import React, { useContext } from 'react'
import GeoContext from 'contexts/geoContext'
import LuxuryEscapesSmallLogo from './LuxuryEscapesSmallLogo'
import LuxuryEscapesSmallLogoUkFlag from './LuxuryEscapesSmallLogoUkFlag'
import LuxuryEscapesLogo from './LuxuryEscapesLogo'
import LuxuryEscapesLogoUkFlag from './LuxuryEscapesLogoUkFlag'
import styled from 'styled-components'
import clsx from 'clsx'
import { mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'

const HideableLuxuryEscapesLogo = styled(LuxuryEscapesLogo)`
  &.hidden-at-mobile {
    ${mediaQueryOnly.mobile} { display: none; }
  }
  &.hidden-at-tablet {
    ${mediaQueryOnly.tablet} { display: none; }
  }
  &.hidden-at-desktop-and-up {
    ${mediaQueryUp.desktop} { display: none; }
  }
`

const HideableLuxuryEscapesSmallLogo = styled(LuxuryEscapesSmallLogo)`
  &.hidden-at-mobile {
    ${mediaQueryOnly.mobile} { display: none; }
  }
  &.hidden-at-tablet {
    ${mediaQueryOnly.tablet} { display: none; }
  }
  &.hidden-at-desktop-and-up {
    ${mediaQueryUp.desktop} { display: none; }
  }
`
const HideableLuxuryEscapesLogoUkFlag = styled(LuxuryEscapesLogoUkFlag)`
  &.hidden-at-mobile {
    ${mediaQueryOnly.mobile}{ display: none; }
  }
  &.hidden-at-tablet {
    ${mediaQueryOnly.tablet} { display: none; }
  }
  &.hidden-at-desktop-and-up {
    ${mediaQueryUp.desktop} { display: none; }
  }
`
const HideableLuxuryEscapesSmallLogoUkFlag = styled(LuxuryEscapesSmallLogoUkFlag)`
  &.hidden-at-mobile {
    ${mediaQueryOnly.mobile}{ display: none; }
  }
  &.hidden-at-tablet {
    ${mediaQueryOnly.tablet} { display: none; }
  }
  &.hidden-at-desktop-and-up {
    ${mediaQueryUp.desktop} { display: none; }
  }
`

export interface ResponsiveLogoProps {
  /** @default icon+text */
  mobileVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  tabletVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  desktopVariant?: 'icon' | 'icon+text'
}

type Props = React.SVGAttributes<SVGSVGElement> & ResponsiveLogoProps

function ResponsiveLuxuryEscapesLogo(props: Props) {
  const {
    mobileVariant = 'icon+text',
    tabletVariant = 'icon+text',
    desktopVariant = 'icon+text',
    className,
    ...rest
  } = props

  const geo = useContext(GeoContext)

  return <>
    {geo.currentRegionCode !== 'GB' && <>
      <HideableLuxuryEscapesSmallLogo
        {...rest}
        width={35}
        className={clsx(className, {
          'hidden-at-mobile': mobileVariant !== 'icon',
          'hidden-at-tablet': tabletVariant !== 'icon',
          'hidden-at-desktop-and-up': desktopVariant !== 'icon',
        })}
      />
      <HideableLuxuryEscapesLogo
        {...rest}
        className={clsx(className, {
          'hidden-at-mobile': mobileVariant !== 'icon+text',
          'hidden-at-tablet': tabletVariant !== 'icon+text',
          'hidden-at-desktop-and-up': desktopVariant !== 'icon+text',
        })}
      />
    </>}
    {geo.currentRegionCode === 'GB' && <>
      <HideableLuxuryEscapesSmallLogoUkFlag
        {...rest}
        width={53}
        className={clsx(className, {
          'hidden-at-mobile': mobileVariant !== 'icon',
          'hidden-at-tablet': tabletVariant !== 'icon',
          'hidden-at-desktop-and-up': desktopVariant !== 'icon',
        })}
      />
      <HideableLuxuryEscapesLogoUkFlag
        {...rest}
        className={clsx(className, {
          'hidden-at-mobile': mobileVariant !== 'icon+text',
          'hidden-at-tablet': tabletVariant !== 'icon+text',
          'hidden-at-desktop-and-up': desktopVariant !== 'icon+text',
        })}
      />
    </>}
  </>
}

export default ResponsiveLuxuryEscapesLogo
