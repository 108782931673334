import React from 'react'

interface Props extends React.SVGAttributes<SVGSVGElement> {}

function LuxuryEscapesLogo(props: Props) {
  return (
    <svg {...props} viewBox="0 0 319.8 52.9">
      <path d="M72 35v2.4H59.7V15.1h2.5V35H72zM92.2 28.2c0 5.8-3.6 9.3-9.4 9.3s-9.4-3.5-9.4-9.3V15.1H76v13.1c0 4.3 2.6 6.9 6.8 6.9s6.8-2.6 6.8-6.9V15.1h2.6zM114.3 37.4h-3l-6.8-9.3-6.7 9.3h-3l8.2-11.5-7.7-10.8h3l6.2 8.6 6.2-8.6h3L106 25.8l8.2 11.6zM135.9 28.2c0 5.8-3.5 9.3-9.3 9.3s-9.4-3.5-9.4-9.3V15.1h2.5v13.1c0 4.3 2.6 6.9 6.9 6.9s6.7-2.6 6.7-6.9V15.1h2.6zM151.2 30.3h-7.5v7h-2.5V15.1h8.5c5.5 0 8.7 2.7 8.7 7.5 0 3.7-1.7 6.2-5 7.2l5.3 7.6h-3zm-1.5-2.4c4 0 6.3-1.8 6.3-5.3s-2.3-5-6.3-5h-6v10.3zM170.9 37.4h-2.5v-7.6l-8.7-14.7h2.7l7.2 11.6 7.1-11.6h2.7l-8.5 14.7v7.6zM185.2 18.5v6h11v3.4h-11V34h12.6v3.4h-16.3V15.1h16v3.4h-12.3zM216.6 17.2l-1.6 3.3a13.4 13.4 0 00-6.5-2c-2.2 0-3.7.8-3.7 2.3 0 4.9 12 2.3 12 10.3 0 4-3.5 6.4-8.4 6.4a13.8 13.8 0 01-9-3.5l1.6-3.3a11.8 11.8 0 007.5 3.3c2.6 0 4.2-1 4.2-2.7 0-5-12-2.2-12-10.1 0-3.9 3.2-6.3 8-6.3a14.5 14.5 0 017.9 2.3M238.6 18.4l-2.2 2.8a8.7 8.7 0 00-6.2-2.9 7.9 7.9 0 100 15.8 9.2 9.2 0 006.2-2.7l2.3 2.5a12.7 12.7 0 01-8.7 3.6 11.3 11.3 0 01-11.6-11.3c0-6.3 5.1-11.2 11.7-11.2a12.3 12.3 0 018.5 3.4M244.5 32.4l-2.1 5h-4l10-22.3h3.8l9.7 22.3h-4l-2.1-5zm5.6-13.3L246 29h8.4zM272.8 15c5.6 0 8.8 2.9 8.8 7.7 0 5-3.2 8-8.8 8h-5.1v6.7h-3.8V15.1zm-5.1 12.3h5c3.4 0 5.4-1.5 5.4-4.5s-2-4.3-5.4-4.3h-5zM288.2 18.5v6H299v3.4h-11V34h12.6v3.4h-16.3V15.1h16v3.4h-12.2zM319.5 17.2l-1.6 3.3a13.4 13.4 0 00-6.5-2c-2.2 0-3.6.8-3.6 2.3 0 4.9 12 2.3 12 10.3 0 4-3.5 6.4-8.4 6.4a13.8 13.8 0 01-9.1-3.5l1.6-3.3a11.8 11.8 0 007.5 3.3c2.7 0 4.2-1 4.2-2.7 0-5-12-2.2-12-10.1 0-3.9 3.3-6.3 8.1-6.3a14.5 14.5 0 017.8 2.3M32.2 21.5c.5-1 1.5-3 .7-4s-2.2.7-2.7 1.3a16.9 16.9 0 00-3.3 7.6 9 9 0 005.3-5"/>
      <path d="M39.2 43.6a18.4 18.4 0 003.9-3.2 23.7 23.7 0 003.3-4.9 20.4 20.4 0 01-4.5 5.8 29.6 29.6 0 003.3-4c-3.8 5.1-15 10-21.6 7.6-.4-.2.6-.1.6-.2a19.9 19.9 0 0021-18.2v-1.2a19.9 19.9 0 00-37.8-9 21.9 21.9 0 009.7 29.4 9.8 9.8 0 01-.9-.6 21.4 21.4 0 006.3 2.4 21.9 21.9 0 01-5.4-1.8c5 3 12.7 2.7 18 .5l-1.7.5a22.9 22.9 0 01-4.6.8l4.6-.8a20.4 20.4 0 004.7-2c-.2 0 0 0 .6-.5a17.8 17.8 0 002-1.4h.1a21 21 0 003.4-3.6l-1.1 1.2a16.2 16.2 0 01-4 3.2m-9.3-28a3.2 3.2 0 012.7.3 8.2 8.2 0 012.6 2c1.4 1.9-.1 4.1-1.4 5.6a13.2 13.2 0 01-5.3 3.6c-.4.1-1.5.3-1.6.6a4 4 0 000 1.7c.1.8.4 2 1.4 2.3a2.7 2.7 0 003.5-3.1 3.5 3.5 0 00-.1-.7 2.2 2.2 0 01-.2-.6c0-.6.7-.5 1-.3 1 .4 1.3.6 1.2 1.5a4.8 4.8 0 01-4.7 4.1c-2.8.1-4-2.7-4.3-5 0 0 0 .2 0 0 0-.6 0-.3 0 0a15.4 15.4 0 011.4-7c.7-2 1.9-4.3 3.9-5m-9.7 22.8a4.9 4.9 0 00-2.1.1c-.6.2-1 .7-1.5 1s-1-.1-.8-.7l.5-.8.3-.9a30.4 30.4 0 001.2-4.3l3.6-18a21.2 21.2 0 00-2.6 3c-.8 1.2-1.1 4-.8 4.3a1 1 0 01.2 1.5c-.4.5-2 0-2.5-.6-2.2-1.9-.2-4.2 1-5.6 1.1-1.3 4.6-3.8 4.8-4.1l1-2a3.5 3.5 0 012.3-2c.3 0 1-.3 1.3 0s0 1-.2 1.4a18.2 18.2 0 00-1.1 3.3l-2 8a433.7 433.7 0 01-3.3 11.8 29.4 29.4 0 01-1.4 3.7c2.2-.6 4.6 0 6.8-.1a10 10 0 003.3-.6c.5-.2 2-1.4 2.1-.2a3 3 0 01-1.8 3.2 5.6 5.6 0 01-4.4-.4 12.3 12.3 0 00-4-1"/>
    </svg>
  )
}

export default LuxuryEscapesLogo
